import React from 'react'
import { useTranslation } from 'react-i18next'
import CollapsableQuestion from './CollapsableQuestion'
import { Accordion } from 'react-bootstrap'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import './FAQ.css';
import { API_BASE_URL} from '../../api/HerpiAPI'; 
import { getAnalytics, logEvent } from "firebase/analytics";

export default function FAQ() {
    const { t } = useTranslation()
    const [faq, setFaq] = useState([])
    const analytics = getAnalytics()

    logEvent(analytics, 'open_faq_in_web', {
        content_type: 'image',
        content_id: 'P12453'
      });

    useEffect(() => {
        const fetch = async function () {
            const result = await axios.get(API_BASE_URL + 'faq', {
                headers: { "Accept-Language": localStorage.getItem("lang") }
            });
            setFaq(result.data);
        };
        fetch();
    }, [])


    return (
        <div className='container'>
            <h3 style={{ textAlign: "start", marginTop: "24px", fontWeight: "bolder" }}>{t("faq")}</h3>

            <Accordion style={{ marginTop: "25px" }}>
                {
                    faq.map((i, index) => (<CollapsableQuestion question={i.question} answer={i.answer} position={index} />))
                }
            </Accordion>

            <br />
            <br />
            <br />
        </div>
    )
}
