import React from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';

export const API_BASE_URL = "https://api.herpi.ge/api/v1/"

const api = axios.create(
     {
          baseURL: API_BASE_URL,
          headers: {
               "Accept-Language": localStorage.getItem("lang")
          }
     }
)

const AxiosGet = (endpoint) => {
     const [response, setResponse] = useState([]);
     const [error, setError] = useState('');
     const [loading, setLoading] = useState(true);

     useEffect(() => {
          const controller = new AbortController();
          const fetch = async () => {
               try {
                    const result = await api.get(endpoint).data;
                    console.log(result);
                    setResponse(result);
               } catch (err) {
                    alert(err.message);
                    setError(err.message);
               } finally {
                    setLoading(false);
               }

               return () => controller.abort();
          }
          fetch();
     }, []);

     return { response, error, loading };
}

export default AxiosGet