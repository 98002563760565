import React from 'react'
import { useTranslation } from 'react-i18next'

export default function NearbyReptileItem({ id, image, name, scientificName, isVenomous, hasMildVenom }) {
     const { t } = useTranslation();

     return (
          <div style={{ padding: "12px" }}>
               <div className="card" style={{ width: "17rem", height: "fit-content" }}>
                    <a href={"/reptiles/" + id + "/details"} style={{ color: "black" }}>
                         <img className="card-img-top" src={image} style={{ width: "15rem", height: "10rem" }} />
                         <div className="card-body">

                              {(
                                   function () {
                                        if (isVenomous) {
                                             return (
                                                  <span className="badge rounded-pill bg-danger" style={{ color: "white" }}>{t('venomous')}</span>
                                             )
                                        } else if (hasMildVenom) {
                                             return (
                                                  <span className="badge rounded-pill bg-warning">{t('mildly_venomous')}</span>
                                             )
                                        } else {
                                             return (
                                                  <span className="badge rounded-pill bg-success" style={{ color: "white" }}>{t('harmless')}</span>
                                             )
                                        }
                                   }
                              )()
                              }

                              <div className="card-body">
                                   <p className='h6'><b>{name}</b></p>
                                   <p className="card-text">{scientificName}</p>
                              </div>
                         </div>
                    </a>
               </div>
          </div>
     )
}
