import React from 'react'
import { Facebook, Instagram } from 'iconsax-react'
import fb from '../../images/facebook.png'
import ig from '../../images/ig.svg'
import twitter from '../../images/twitter.png'
import linkedin from '../../images/linkedin.png'
import github from '../../images/github.svg'
import { padding } from '@mui/system';
import avatar from '../../images/developer_avatar.jpg';

function Contact() {
     document.title = 'დეველოპერთან კონტაქტი';

     return (
          <div className='container' style={{ height: "100%", width: "100%" }}>
               <div className='rounded-bg-main'>
                    <div className='row' style={{ padding: " 0 20px 0 20px" }}>
                         <div>
                              <b style={{ width: "100%", textAlign: "start" }}>დეველოპერთან კონტაქტი</b>

                              <div className='col' style={{ marginTop: "20px" }}>
                                   <div style={{ display: "flex", alignItems: "center", alignContent: "center", flexDirection: "column" }}>
                                        <img className="img-avatar" src={avatar}
                                             style={{
                                                  width: "130px",
                                                  height: "130px",
                                                  objectFit: "cover",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "cover",
                                                  backgroundPosition: "50% 50%",
                                                  marginRight: "auto",
                                                  marginLeft: "auto",
                                                  marginTop: "15px",
                                                  marginBottom: "15px"
                                             }} />

                                        <div style={{ fontFamily: "Calibri", display: "flex", flexDirection: "column", margin: "auto", paddingLeft: "20px", paddingRight: "20px" }}>

                                             <div style={{ display: "flex", flexDirection: "column" }}>
                                                  <span style={{ width: "100%", textAlign: "center", fontSize: "x-large", fontWeight: "bold" }}>George Gigauri</span>
                                                  <a href='mailto:giorgi@herpi.ge' style={{ lineHeight: "normal", fontWeight: "0", color: "dimgray", textAlign: "start", width: "100%", textAlign: "center" }}>giorgi@herpi.ge</a>
                                             </div>

                                             <div style={{ marginTop: "20px", }}>
                                                  <a href='https://www.facebook.com/giorgii.gigauri' target="_blank">
                                                       <img src={fb} style={{ width: "42px", height: "42px", marginRight: "10px" }} />
                                                  </a>

                                                  <a href='https://www.instagram.com/george_gigauri' target="_blank">
                                                       <img src={ig} style={{ width: "42px", height: "42px", marginRight: "10px" }} />
                                                  </a>

                                                  <a href='https://www.twitter.com/georgegigauri7' target="_blank">
                                                       <img src={twitter} style={{ width: "42px", height: "42px", marginRight: "10px" }} />
                                                  </a>

                                                  <a href='https://www.linkedin.com/in/giorgi-gigauri-934a301a8/' target="_blank">
                                                       <img src={linkedin} style={{ width: "42px", height: "42px", marginRight: "10px" }} />
                                                  </a>

                                                  <a href='https://www.github.com/george-gigauri' target="_blank">
                                                       <img src={github} style={{ width: "42px", height: "42px", marginRight: "10px" }} />
                                                  </a>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>

                    </div>
               </div>
          </div>
     )
}

export default Contact