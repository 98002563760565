import React from 'react'
import { useTranslation } from 'react-i18next'

function ReptileItem({ id, image, isVenomous, hasMildVenom, name, scientificName }) {
     const { t } = useTranslation();

     return (
          <div className="col-12 col-md-6 col-lg-4" style={{ marginTop: "10px" }}>

               <a href={'/reptiles/' + id + '/details'} style={{ color: "black", textDecoration: "none" }}>
                    <div class="card">
                         <img src={image} className="card-img-top" style={{ objectFit: "cover", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "50% 50%" }} />
                         <div class="card-body">
                              {(
                                   function () {
                                        if (isVenomous) {
                                             return (
                                                  <span className="badge rounded-pill bg-danger" style={{ color: "white" }}>{t('venomous')}</span>
                                             )
                                        } else if (hasMildVenom) {
                                             return (
                                                  <span className="badge rounded-pill bg-warning" style={{ color: "black" }}>{t('mildly_venomous')}</span>
                                             )
                                        } else {
                                             return (
                                                  <span className="badge rounded-pill bg-success" style={{ color: "white" }}>{t('harmless')}</span>
                                             )
                                        }
                                   }
                              )()
                              }

                              <div className="card-body" style={{ marginTop: "10px" }}>
                                   <b className="h6"><b>{name}</b></b>
                                   <span className="card-text">{scientificName}</span>
                              </div>
                         </div>

                         <a href={'/reptiles/' + id + '/details'} className="btn rounded-pill btn-primary" style={{ margin: "0px 15px 15px 15px" }}>{t('read_more')}</a>
                    </div>
               </a>
          </div>
     )
}

export default ReptileItem