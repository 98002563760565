import './App.css';
import React, { useState } from 'react';
import Header from './fragment/header/Header';
import Home from './pages/home/Home';
import { Route, Routes } from 'react-router-dom'
import ReptileDetails from './pages/details/ReptileDetails.js';
import { initializeApp } from "firebase/app";
import { getAnalytics } from 'firebase/analytics'
import Contact from './pages/contact/Contact';
import { Modal, Button } from 'react-bootstrap';
import reptiles_in_georgia_thumbnail from './images/reptiles_in_georgia_thumbnail.jpg'
import { OpenInAppBanner } from 'react-open-in-app-banner';
import ic_logo from './images/lizard.png'
import FAQ from './pages/faq/FAQ';
import NearbyReptiles from './pages/nearby_reptiles/NearbyReptiles'

function App() {

  const firebaseConfig = {
    apiKey: "AIzaSyCF1BfspHzDcqGzGJDYfKE0dxE2dhkm7Zg",
    authDomain: "reptiledb-96f41.firebaseapp.com",
    projectId: "reptiledb-96f41",
    storageBucket: "reptiledb-96f41.appspot.com",
    messagingSenderId: "520180770046",
    appId: "1:520180770046:web:87e83c9a4ab74fe4ae71af",
    measurementId: "G-9VY5SH1JXH"
  };

  const app = initializeApp(firebaseConfig);
  getAnalytics(app);

  const isNeverShowJoinGroupModal = localStorage.getItem('show_join_group_modal');
  const [showJoinGroupModal, setShowJoinGroupModal] = useState(false);

  const onNeverShowJoinGroup = () => {
    localStorage.setItem('show_join_group_modal', false);
    setShowJoinGroupModal(false);
  }

  return (
    <div className="App">

      <Header />

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/reptiles/:id/details' element={<ReptileDetails />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/faq' element={<FAQ />} />
        <Route path='/nearby' element={<NearbyReptiles />} />
      </Routes>

      {
        (function () {

          function isAndroid() {
            try {
              var ua = navigator.userAgent.toLowerCase();
              return ua.indexOf("android") > -1;
            } catch (e) {
              return false;
            }
          }

          function isiOS() {
            try {
              return !!navigator.platform.match(/iPhone|iPod|iPad/);
            } catch (e) {
              return false;
            }
          }

          if (isAndroid()) {
            return (
              <OpenInAppBanner
                showOnWeb
                appName="Herpi / ჰერპი"
                bannerDescription="By George Gigauri on Google Play"
                playStoreAppId="ge.gigauri.reptiledb"
                buttonColor='#0D47A1'
                openButtonLabel='OPEN APP'
                style={{ textAlign: "start" }}
                playStoreBaseHref="https://play.google.com/store/apps/details?id=com.gigauri.reptiledb"
                onOpenStoreLink={() => {
                  window.open('https://play.google.com/store/apps/details?id=com.gigauri.reptiledb', '_blank');
                }}
                appIcon={
                  <img
                    style={{ width: '2.5rem', height: '2.5rem', backgroundColor: "white", borderRadius: "12px", borderWidth: "1px", borderColor: "#0D47A1" }}
                    src={ic_logo}
                    alt="example"
                  />
                }
              />
            )
          } else if(isiOS()) {
            <OpenInAppBanner
                showOnWeb
                appName="Herpi / ჰერპი"
                bannerDescription="By Koka Wirgvava on App Store"
                appStoreAppId="ge.kdot.herpi"
                appStoreAppName='Herpi / ჰერპი'
                appStoreBaseHref='https://apps.apple.com/ge/app/herpi/id6478537718'
                buttonColor='#0D47A1'
                openButtonLabel='INSTALL'
                style={{ textAlign: "start" }}
                onOpenStoreLink={() => {
                  window.open('https://apps.apple.com/ge/app/herpi/id6478537718', '_blank');
                }}
                appIcon={
                  <img
                    style={{ width: '2.5rem', height: '2.5rem', backgroundColor: "white", borderRadius: "12px", borderWidth: "1px", borderColor: "#0D47A1" }}
                    src={ic_logo}
                    alt="example"
                  />
                }
              />
          }
        })()
      }

      <Modal
        show={showJoinGroupModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ overflow: "hidden", width: "fit-content", margin: "auto" }}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" style={{ fontWeight: "bold", fontSize: "18px" }}>
            შემოგვიერთდი Facebook ჯგუფში
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={reptiles_in_georgia_thumbnail} width="100%" height="50%" style={{ borderRadius: "10px" }} />
          <p style={{ marginTop: "10px", fontWeight: "bolder" }}>რეპტილიები საქართველოში</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={onNeverShowJoinGroup}>აღარ მაჩვენო</Button>
          <Button onClick={() => {
            window.open('https://www.facebook.com/groups/850826798633775', '_blank').focus();
          }}>გაწევრიანება</Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
}

export default App;