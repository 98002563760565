import React from 'react'
import { Accordion } from 'react-bootstrap'

export default function CollapsableQuestion({ question, answer, position }) {
    return (
        <>
            <Accordion.Item eventKey={position}>
                <Accordion.Header>{question}</Accordion.Header>
                <Accordion.Body style={{ paddingBottom: "24px" }}>
                    {answer}
                </Accordion.Body>
            </Accordion.Item>
        </>
    )
}