
class CrawlerService {

    static getBaseUrl() {
        const protocol = window.location.protocol
        const hostname = window.location.hostname
        const port = 3001
        return `${protocol}//${hostname}`
    }

    static async get({ id }) {
        const lang = localStorage.getItem("lang");
        const response = await fetch(`${this.getBaseUrl()}/crawlers/fetch/${id}/${lang}`, {
            method: "GET"
        });

        return await response.json();
    }

    static async add({ id, name, scientificName, description, image }) {
        if(id === undefined || id === null || id === -1) {
            return;
        }

        const lang = localStorage.getItem("lang")
        await fetch(this.getBaseUrl() + "/crawlers/write", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: id,
                lang: lang,
                name: name,
                scientificName: scientificName,
                description: description,
                image: image
            })
        });
    }
}

module.exports = CrawlerService