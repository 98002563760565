import React, { useState, useEffect } from "react"
import './NearbyReptilesStyle.css'
import axios from 'axios'
import ReptileItem from "../../component/ReptileItem";
import NearbyReptileItem from "../../component/NearbyReptileItem";
import LinearProgress from '@mui/material/LinearProgress';
import GooglePlayBanner from '../../images/get-it-on-google-play.png';
import { useTranslation } from 'react-i18next';
import { width } from "@mui/system";
import locationIcon from '../../images/location.svg';
import { Modal, Button } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';
import locationPin from '../../images/location_pin.png';
import ic_shortcut from '../../images/exit-top-right.png';
import { API_BASE_URL } from '../../api/HerpiAPI';
import { getAnalytics, logEvent } from "firebase/analytics";
import { useSearchParams } from 'react-router-dom';

export default function NearbyReptiles() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const lat = searchParams.get("lat");
  const lng = searchParams.get("lng");
  const type = searchParams.get("type");
  let isAuto = searchParams.get("isAuto");

  const [isLocationPickupShown, setIsLocationPickupShown] = useState(false);
  const [selectedLatitude, setSelectedLatitude] = useState(lat);
  const [selectedLongitude, setSelectedLongitude] = useState(lng);

  const [loadingNearby, setLoadingNearby] = useState(false);
  const [data, setData] = useState([]);
  const [isLocationAvailable, setIsLocationAvailable] = useState(false);

  logEvent(getAnalytics(), 'nearby_reptiles_page_opened_in_web', {
    content_type: 'html',
    content_id: 'nearby'
  });

  if (lat === null || lng === null) {
    navigator.geolocation.getCurrentPosition((location) => {
      setIsLocationAvailable(true);
      const latitude = location.coords.latitude;
      const longitude = location.coords.longitude;

      window.location.href = 'nearby?lat=' + latitude + '&lng=' + longitude + '&isAuto=true';
    });
  }

  useEffect(function () {
    const loadNearby = async () => {

      let url
      if (type != null) {
        url = 'reptiles/nearby?lat=' + lat + '&lng=' + lng + "&type=" + type
      } else {
        url = 'reptiles/nearby?lat=' + lat + '&lng=' + lng
      }

      setLoadingNearby(true);

      const result = await axios.get(API_BASE_URL + url, {
        headers: {
          "Accept-Language": localStorage.getItem("lang")
        }
      });

      setData(result.data);
      setLoadingNearby(false);
    }

    if (lat != null && lng != null) {
      loadNearby();
    }
  }, []);


  return (
    <>
      <div className="container">
        <p></p>
        <p></p>
        <p></p>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <h4>{isAuto === 'true' ? 'შენს არეალში გავრცელებული სახეობები' : 'მონიშნულ ტერიტორიაზე გავრცელებული სახეობები'}</h4>
          <Button variant='secondary' onClick={() => { setIsLocationPickupShown(true) }} style={{ fontSize: "16px", paddingTop: "8px", paddingBottom: "8px" }}>სხვა ადგილის მონიშვნა</Button>
        </div>
        <p></p>
        <p></p>
        <p></p>

        {
          loadingNearby ?
            <div className="spinner-border text-primary" role="status" style={{ width: "8rem", height: "8rem", marginTop: "25px" }}>
              <span className="visually-hidden"></span>
            </div> : null
        }

        {
          (!isLocationAvailable && !loadingNearby && data.length == 0) ?
            <>
              <div id="alert-nearby" className="alert alert-danger" role="alert" style={{ lineHeight: "30px" }}>
                <div className="container">
                  {t('could_not_detect_location')}
                  <span style={{ margin: "15px" }} />
                  <span style={{ fontWeight: "bolder", cursor: "pointer" }} onClick={() => { setIsLocationPickupShown(true) }}>{t('manual_select')}</span>
                </div>
              </div>
            </>
            : null
        }

        <div className='horizontal-paddings-15'>
          <div className='row justify-content-start'>

            {data.map(r => (
              <ReptileItem
                id={r.id}
                image={r.image}
                isVenomous={r.venomous}
                hasMildVenom={r.hasMildVenom}
                name={r.name}
                scientificName={r.family.name} />
            ))}

          </div>
        </div>
        <p></p>
        <p></p>
        <p></p>
      </div>

      <Modal
        size="xl"
        show={isLocationPickupShown}
        onHide={() => { setIsLocationPickupShown(false) }}
        centered
        aria-labelledby="example-modal-sizes-title-sm">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm" style={{ width: "100%" }}>
            <span style={{ fontSize: "18px", textAlign: "center", width: "100%" }}>
              მონიშნეთ სასურველი წერტილი
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div style={{ height: '25rem', width: '100%', borderRadius: "15px", display: "inline-block", overflow: "hidden" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyCYTXM9m7pA746wxwftWfDjku61ekNm98g' }}
              defaultCenter={{ lat: 42.057603, lng: 43.815661 }}
              defaultZoom={7.5}
              onClick={(e) => {
                setSelectedLatitude(e.lat)
                setSelectedLongitude(e.lng)
              }}>

              {
                (
                  () => {
                    if (selectedLatitude != null && selectedLongitude != null) {
                      return (<img lat={selectedLatitude} lng={selectedLongitude} src={locationPin} style={{ width: "35px", height: "35px", marginTop: "-35px", marginLeft: "-20px" }} />)
                    }
                  }
                )()
              }

            </GoogleMapReact>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => { setIsLocationPickupShown(false); }}>გაუქმება</Button>
          <Button variant='success' onClick={() => {
            setIsLocationPickupShown(false);
            window.location.href = "nearby?lat=" + selectedLatitude + "&lng=" + selectedLongitude
          }}>დასრულება</Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}