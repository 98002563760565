import React, { useState, useEffect } from "react"
import './HomeStyle.css'
import axios from 'axios'
import ReptileItem from "../../component/ReptileItem";
import NearbyReptileItem from "../../component/NearbyReptileItem";
import LinearProgress from '@mui/material/LinearProgress';
import GooglePlayBanner from '../../images/get-it-on-google-play.png';
import { useTranslation } from 'react-i18next';
import { width } from "@mui/system";
import locationIcon from '../../images/location.svg';
import { Modal, Button } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';
import locationPin from '../../images/location_pin.png';
import ic_shortcut from '../../images/exit-top-right.png';
import { API_BASE_URL } from '../../api/HerpiAPI';
import { getAnalytics, logEvent } from "firebase/analytics";

function Home() {
  const { t } = useTranslation();

  const [loadingNearby, setLoadingNearby] = useState(true);
  const [loadingAll, setLoadingAll] = useState(true);
  const [isLocationAvailable, setIsLocationAvailable] = useState(false);
  const [type, setType] = useState(null);

  const [manualPosition, setManualPosition] = useState(null);
  const [isLocationPickupShown, setIsLocationPickupShown] = useState(false);
  const [nearbyReptiles, setNearbyReptiles] = useState([]);
  const [allReptiles, setAllReptiles] = useState([]);

  const analytics = getAnalytics();

  const [selectedLatitude, setSelectedLatitude] = useState(null)
  const [selectedLongitude, setSelectedLongitude] = useState(null)

  logEvent(analytics, 'landing_page_opened', {
    content_type: 'html',
    content_id: 'home'
  });

  useEffect(function () {

    let url
    if (type != null) {
      url = 'reptiles?status=PUBLISHED&type=' + type
    } else {
      url = 'reptiles?status=PUBLISHED'
    }

    setLoadingAll(true);
    const fetch = async function () {
      const result = await axios.get(API_BASE_URL + url, {
        headers: {
          "Accept-Language": localStorage.getItem("lang")
        }
      });
      setAllReptiles(result.data.sort(r => r.venomous ? 1 : -1));
      setLoadingAll(false);
    };

    fetch();
  }, [type]);

  useEffect(function () {

    if (!manualPosition) {
      setLoadingNearby(false)
      navigator.geolocation.getCurrentPosition((location) => {
        setIsLocationAvailable(true);
        setLoadingNearby(true);

        const lat = () => {
          if (manualPosition) return manualPosition.lat
          else return location.coords.latitude;
        }
        const lng = () => {
          if (manualPosition) return manualPosition.lng
          else return location.coords.longitude;
        }

        const loadNearby = async () => {
          let url
          if (type != null) {
            url = 'reptiles/nearby?lat=' + lat() + '&lng=' + lng() + "&type=" + type
          } else {
            url = 'reptiles/nearby?lat=' + lat() + '&lng=' + lng()
          }

          const result = await axios.get(API_BASE_URL + url, {
            headers: {
              "Accept-Language": localStorage.getItem("lang")
            }
          });
          setNearbyReptiles(result.data);
          setLoadingNearby(false);
        }

        loadNearby();
      });
    } else {
      const loadNearby = async () => {
        const result = await axios.get(API_BASE_URL + 'reptiles/nearby?lat=' + manualPosition.lat + '&lng=' + manualPosition.lng + "&type=" + type, {
          headers: {
            "Accept-Language": localStorage.getItem("lang")
          }
        });
        setNearbyReptiles(result.data);
        setLoadingNearby(false);
        setIsLocationAvailable(false);
      }

      loadNearby();
    }
  }, [manualPosition, type]);

  function openPickLocation() {
    setIsLocationPickupShown(true);
  }

  return (
    <div className='container'>

      <div id="faq-container" onClick={() => {
        window.location.href = "/faq"
      }}>
        <h6 style={{ width: "100%" }} className="text-secondary">{t('faq')}</h6>
        <img style={{ width: "20px", height: "20px", position: "absolute", top: "50%", left: "auto", right: "12", transform: "translate(-50%,-50%)" }} src={ic_shortcut} />
      </div>

      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <h6 className='text-secondary' style={{ marginTop: "25px" }}>{t('in_your_area')}</h6>
        <div style={{ marginTop: "25px", marginLeft: "auto", marginRight: "0", display: "flex", flexDirection: "row", width: "fit-content", cursor: "pointer" }} onClick={openPickLocation}>
          <img src={locationIcon} style={{ width: "20px", height: "20px", marginTop: "auto", marginBottom: "auto" }} />
          <h6 className='text-secondary' style={{ marginTop: "auto", marginBottom: "auto", marginLeft: "5px" }}>{t('manual_select')}</h6>
        </div>
      </div>

      {
        !isLocationAvailable && !loadingNearby ?
          <div id="alert-nearby" className="alert alert-danger" role="alert">
            <div>
              {t('could_not_detect_location')}
            </div>
          </div>
          : null
      }

      {
        loadingNearby ?
          <div style={{
            paddingTop: "25px",
            width: "auto", display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
            <LinearProgress style={{ width: "20%" }} />
          </div> : null
      }

      <div className='horizontal-scrollable'>
        {nearbyReptiles.map(r => (
          <NearbyReptileItem
            id={r.id}
            image={r.image}
            isVenomous={r.venomous}
            hasMildVenom={r.hasMildVenom}
            name={r.name}
            scientificName={r.family.name} />
        ))}
      </div>

      <div className="horizontal-scrollable" style={{ paddingTop: "15px", display: "flex", flexDirection: "row" }}>
        <h6 className={'badge rounded-pill bg-primary ' + (type === null ? 'tab-menu-item-active' : "tab-menu-item")} onClick={() => { setType(null) }}>{t('all')}</h6>
        <h6 className={'badge rounded-pill bg-primary ' + (type === "SNAKE" ? 'tab-menu-item-active' : "tab-menu-item")} onClick={() => { setType("SNAKE") }}>{t('snakes')}</h6>
        <h6 className={'badge rounded-pill bg-primary ' + (type === "LIZARD" ? 'tab-menu-item-active' : "tab-menu-item")} onClick={() => { setType("LIZARD") }}>{t('lizards')}</h6>
        <h6 className={'badge rounded-pill bg-primary ' + (type === "SCORPION" ? 'tab-menu-item-active' : "tab-menu-item")} onClick={() => { setType("SCORPION") }}>{t('scorpions')}</h6>
      </div>

      {
        loadingAll ?
          <div className="spinner-border text-primary" role="status" style={{ width: "10rem", height: "10rem", marginTop: "25px" }}>
            <span className="visually-hidden"></span>
          </div> : null
      }

      <div className='horizontal-paddings-15'>
        <div className='row justify-content-start'>

          {allReptiles.map(r => (
            <ReptileItem
              id={r.id}
              image={r.image}
              isVenomous={r.venomous}
              hasMildVenom={r.hasMildVenom}
              name={r.name}
              scientificName={r.family.name} />
          ))}

        </div>
      </div>
      <p></p>
      <p></p>
      <p></p>
      <p></p>

      <Modal
        size="xl"
        show={isLocationPickupShown}
        onHide={() => { setIsLocationPickupShown(false) }}
        centered
        aria-labelledby="example-modal-sizes-title-sm">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm" style={{ width: "100%" }}>
            <span style={{ fontSize: "18px", textAlign: "center", width: "100%" }}>
              მონიშნეთ სასურველი წერტილი
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div style={{ height: '25rem', width: '100%', borderRadius: "15px", display: "inline-block", overflow: "hidden" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyCYTXM9m7pA746wxwftWfDjku61ekNm98g' }}
              defaultCenter={{ lat: 42.057603, lng: 43.815661 }}
              defaultZoom={6.2}
              onClick={(e) => {
                setSelectedLatitude(e.lat)
                setSelectedLongitude(e.lng)
              }}>

              {
                (
                  () => {
                    if (selectedLatitude != null && selectedLongitude != null) {
                      return (<img lat={selectedLatitude} lng={selectedLongitude} src={locationPin} style={{ width: "35px", height: "35px", marginTop: "-35px", marginLeft: "-20px" }} />)
                    }
                  }
                )()
              }

            </GoogleMapReact>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => { setIsLocationPickupShown(false); setManualPosition(null); }}>გაუქმება</Button>
          <Button variant='success' onClick={() => {
            setIsLocationPickupShown(false);
            window.location.href = "nearby?lat=" + selectedLatitude + "&lng=" + selectedLongitude
          }}>დასრულება</Button>
        </Modal.Footer>
      </Modal>

    </div >
  )
}

export default Home