import React, { useEffect, useState, useCallback } from 'react'
import './ReptileDetailsStyle.css'
import { useParams } from 'react-router-dom'
import IconBack from '../../images/arrow-left.svg'
import axios from 'axios';
import GoogleMapReact from 'google-map-react';
import ImageViewer from 'react-simple-image-viewer';
import { useTranslation } from 'react-i18next';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { API_BASE_URL } from '../../api/HerpiAPI';
import { getAnalytics, logEvent } from "firebase/analytics";
const Crawler = require('../../crawler/crawlerServer.js');

function ReptileDetails() {
     const analytics = getAnalytics();
     const { t } = useTranslation();
     const { id } = useParams();
     const [data, setData] = useState({ addedBy: {}, gallery: [{ url: "" }], family: {} });

     const getFromDb = async () => {
         const d = await Crawler.get({ id: id });
         document.title = d.name;
     }
     getFromDb();

     const [currentImage, setCurrentImage] = useState(0);
     const [isViewerOpen, setIsViewerOpen] = useState(false);

     const openImageViewer = useCallback(function (index) {
          setCurrentImage(index);
          setIsViewerOpen(true);
     }, []);

     const closeImageViewer = function () {
          setCurrentImage(0);
          setIsViewerOpen(false);
     };

     useEffect(function () {
          axios.get(API_BASE_URL + 'reptiles/' + id, {
               headers: {
                    "Accept-Language": localStorage.getItem("lang")
               }
          }).then(function (response) {
               setData(response.data);
          }).catch(function (error) {
               alert(error);
          }).finally(function () { });

          document.getElementById("back_button").onclick = function () {
               logEvent(analytics, 'navigate_back_from_details', {
                    name: data.name,
                    scientificName: data.scientificName
               });
               window.location.href = "/"
          };
     }, []);

     useEffect(() => {
          const add = async () => {
               Crawler.add({
                    id: data.id,
                    name: data.name,
                    scientificName: data.scientificName,
                    description: data.description,
                    image: data.image
               });
          }
          add();
     }, [data]);

     logEvent(analytics, 'reptile_details_opened', {
          name: data.name,
          scientificName: data.scientificName
     });

     const onMapLoaded = function (map, maps) {

          axios.get(API_BASE_URL + 'reptiles/' + id + '/coverage')
               .then(function (response) {
                    response.data.forEach(function (i) {
                         const coverageArea = new maps.Polygon({
                              paths: i.coordinates,
                              strokeColor: "#FF0000",
                              strokeOpacity: 0.9,
                              strokeWeight: 2,
                              fillColor: "#FF0000",
                              fillOpacity: 0.30
                         });
                         coverageArea.setMap(map);
                    });
               }).catch(function (error) {
                    alert(error);
               }).finally(function () {

               });
     };

     return (
          <div className='container'>
               <HelmetProvider>
                    <Helmet defer={false}>
                         <title>{data.name}</title>
                         <meta property="title" content={data.name} />
                         <meta property="og:title" content={data.name} />
                         <meta property="og:description" content={data.description} />
                         <meta property="description" content={data.description} />
                         <meta property='og:image:secure_url' content={data.image} />
                         <meta property='og:image:url' content={data.image} />
                         <meta property='og:image' content={data.image} />
                         <meta property='og:type' content="website" />
                         <meta property='og:url' content={window.location.href} />
                         <meta name="image" content={data.image} />
                    </Helmet>
               </HelmetProvider>

               <div className='rounded-bg-main'>

                    <div style={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
                         <div id='back_button'>
                              <img src={IconBack} />
                              <span style={{ fontSize: "18px", fontWeight: "bold", marginLeft: "5px", fontFamily: "calibri" }}>{t('back')}</span>
                         </div>

                         <div id='views-count' style={{ marginTop: "auto", marginBottom: "auto" }}>
                              <span style={{ fontSize: "15px", fontWeight: "medium", marginLeft: "5px", fontFamily: "calibri" }}>{t('views')}: {
                                   (
                                        function () {
                                             let num = data.views;
                                             if (num > 999 && num < 1000000) {
                                                  return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
                                             } else if (num > 1000000 && num < 1000000000) {
                                                  return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
                                             } else if (num > 1000000000) {
                                                  return (num / 1000000000).toFixed(1) + 'B'; // convert to M for number from > 1 million 
                                             } else if (num < 900) {
                                                  return num; // if value < 1000, nothing to do
                                             }
                                        }
                                   )()
                              }</span>
                         </div>

                    </div>

                    {(
                         function () {
                              if (data.hasRedFlag) {
                                   return (<div className='danger-alert' style={{ marginTop: "15px" }}>
                                        {t('endemic')}
                                   </div>)
                              }
                         }
                    )()}

                    <div className='row' style={{ paddingTop: "15px", paddingBottom: "15px", width: "100%" }}>

                         <img id='reptile-thumbnail'
                              className='col-sm-4 col-xs-4 col-md-4'
                              src={data.image}
                              style={
                                   {
                                        objectFit: "cover",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                        backgroundPosition: "50% 50%"
                                   }
                              } />

                         <div className='col-sm-4 col-xs-4 col-md-4' style={{ marginTop: "5px", textAlign: "start" }}>
                              <label style={{ fontSize: "20px" }}><b>{data.name}</b></label>
                              <br />
                              {(
                                   function () {
                                        if (data.venomous) {
                                             return (
                                                  <span className="badge rounded-pill bg-danger" style={{ fontSize: "15px", color: "white" }}>{t('venomous')}</span>
                                             )
                                        } else if (data.hasMildVenom) {
                                             return (
                                                  <span className="badge rounded-pill bg-warning" style={{ fontSize: "15px" }}>{t('mildly_venomous')}</span>
                                             )
                                        } else {
                                             return (
                                                  <span className="badge rounded-pill bg-success" style={{ fontSize: "15px", color: "white" }}>{t('harmless')}</span>
                                             )
                                        }
                                   }
                              )()
                              }

                              <p></p>
                              <div>
                                   <text>{t('scientific_name')}</text>
                                   <h6>{data.scientificName}</h6>
                              </div>

                              <p></p>
                              <div>
                                   <text>{t('family')}</text>
                                   <h6>{data.family.name}</h6>
                              </div>

                              <div className='card-rounded-shadowed' style={{ display: "flex", marginTop: "20px" }}>
                                   <img className="img-avatar" src={data.addedBy.avatar}
                                        style={{ objectFit: "cover", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "50% 50%" }} />
                                   <div style={{ marginLeft: "12px" }}>
                                        <text style={{ fontSize: "12px" }}>{t('published_by')}</text>
                                        <h6>{data.addedBy.firstName + " " + data.addedBy.lastName}</h6>
                                   </div>
                              </div>
                         </div>

                         <div className='col-sm-4 col-xs-4 col-md-4'></div>
                    </div>

                    <p style={{ textAlign: "start", marginTop: "25px", fontSize: "18px", fontWeight: "bold" }}>
                         {t('description')}
                    </p>

                    <p style={{ textAlign: "start" }}>
                         {data.description}
                    </p>

                    <p style={{ textAlign: "start", marginTop: "25px", fontSize: "18px", fontWeight: "bold" }}>
                         {t('gallery')}
                    </p>

                    <div className='horizontal-scrollable'>
                         {
                              data.gallery.map(function (i, index) {
                                   return (
                                        <div style={{
                                             minWidth: "18rem",
                                             marginRight: "10px",
                                             cursor: "pointer"
                                        }}>
                                             <img src={i.url}
                                                  onClick={function () { openImageViewer(index) }}
                                                  key={index}
                                                  style={{
                                                       width: "100%",
                                                       height: "10rem",
                                                       borderRadius: "10px",
                                                       objectFit: "cover", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "50% 50%"
                                                  }} />

                                             <text>Ⓒ {(
                                                  () => {
                                                       if (i.credits) {
                                                            if (i.credits.length === 0 || i.credits[0] === '""' || i.credits[0] === "") {
                                                                 return (i.author.firstName + " " + i.author.lastName);
                                                            } else {
                                                                 return i.credits;
                                                            }
                                                       }
                                                  }
                                             )()}
                                             </text>
                                             <br />
                                             <br />
                                        </div>
                                   )
                              })
                         }


                         {isViewerOpen && (
                              <ImageViewer
                                   src={data.gallery.map(i => i.url)}
                                   currentIndex={currentImage}
                                   disableScroll={false}
                                   closeOnClickOutside={false}
                                   onClose={closeImageViewer}
                              />
                         )}
                    </div>

                    <p style={{ textAlign: "start", marginTop: "25px", fontSize: "18px", fontWeight: "bold" }}>
                         {t('coverage_area')}
                    </p>

                    <div style={{ height: '30rem', width: '100%', borderRadius: "15px", display: "inline-block", overflow: "hidden" }}>
                         <GoogleMapReact
                              bootstrapURLKeys={{ key: 'AIzaSyCYTXM9m7pA746wxwftWfDjku61ekNm98g' }}
                              defaultCenter={{ lat: 42.057603, lng: 43.815661 }}
                              defaultZoom={6.2}
                              onGoogleApiLoaded={function ({ map, maps }) { onMapLoaded(map, maps) }}>
                         </GoogleMapReact>
                    </div>
               </div>
          </div>
     )
}

export async function getStaticPaths() {
     const res = await fetch(API_BASE_URL + 'reptiles?status=PUBLISHED');
     const data = await res.json();
     const paths = data.map(reptile => {
          return {
               params: { id: reptile.id.toString() }
          }
     })

     return {
          paths,
          fallback: false
     }
}

export async function getStaticProps(context) {
     const id = context.params.id;
     const res = await fetch(API_BASE_URL + 'reptiles/' + id);
     const data = await res.json();
     return {
          props: {
               data: data
          }
     }
}

export default ReptileDetails