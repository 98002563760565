import React, { useEffect, useState } from 'react'
import './Header.css'
import logo from '../../images/lizard.png'
import { useTranslation } from 'react-i18next'
import { Navbar, Container, Nav, Modal, Button, Dropdown, Row } from 'react-bootstrap'
import { getAnalytics, logEvent } from "firebase/analytics";
import { API_BASE_URL} from '../../api/HerpiAPI'; 

const languageOptions = [
  {
    key: "ka",
    title: "ქართული",
    flag: "https://cdn.countryflags.com/thumbs/georgia/flag-round-250.png"
  },
  {
    key: "en",
    title: "English (US)",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/United-states_flag_icon_round.svg/1024px-United-states_flag_icon_round.svg.png"
  }
]

export default function Header() {
  const analytics = getAnalytics()
  const { t } = useTranslation();
  var defaultLanguage = localStorage.getItem("lang");

  const [isLanguageSet, setIsLanguageSet] = useState(defaultLanguage == null);
  const [activeLanguageIndex, setActiveLanguageIndex] = useState(0);

  useEffect(() => {
    languageOptions.forEach((item, index) => {
      if (item.key === defaultLanguage) {
        setActiveLanguageIndex(index);
      }
    });
  }, []);

  const saveLanguage = (language) => {
    localStorage.setItem("lang", language);
    window.location.reload();
  }

  return (
    <Navbar bg="light" expand="lg" collapseOnSelect>
      <Container>
        <Navbar.Brand href="/" style={{ display: "flex", alignContent: "center", alignItems: "center", marginLeft: "15px" }}>
          <img src={logo} width="35px" height="35px" className="d-inline-block align-top" />
          <b style={{ marginLeft: "10px", fontSize: "xx-large" }}>Herpi</b>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">{t("home")}</Nav.Link>
            <Nav.Link href="/contact">{t("contact")}</Nav.Link>
            <Nav.Link href="https://api.herpi.ge/api/docs" target="_blank">{t("api_docs")}</Nav.Link>
            {/* <Nav.Link href="#">რეკლამა</Nav.Link> */}
          </Nav>

          <Dropdown style={{ margin: "12px" }}>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ fontSize: "15px" }}>
              <img style={{ width: "25px" }} src={languageOptions[activeLanguageIndex].flag} />
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ marginTop: "10px" }}>
              {
                languageOptions.map((i, index) => (
                  <Dropdown.Item onClick={() => { saveLanguage(i.key) }} active={index === activeLanguageIndex}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", paddingTop: "4px", paddingBottom: "4px" }} >
                      <img style={{ width: "30px" }} src={i.flag} />
                      <span style={{ marginLeft: "12px", fontWeight: "bold", fontSize: "medium" }}>{i.title}</span>
                    </div>
                  </Dropdown.Item>
                )
                )
              }
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Collapse>
      </Container>

      <Modal
        show={isLanguageSet}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ overflow: "hidden", borderRadius: "15px" }}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" style={{ fontWeight: "bold", fontSize: "18px" }}>
            აირჩიე ენა
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button variant="light" onClick={() => { saveLanguage("ka") }}>ქართული</Button>
            <Button variant="light" onClick={() => { saveLanguage("en") }} style={{ marginTop: "10px" }}>English</Button>
          </div>
        </Modal.Body>
      </Modal>
    </Navbar>
  );
}
